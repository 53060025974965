import { EventDispatcher } from "../../../core/internal/event/EventDispatcher"
import { EventDto } from "../../../core/internal/event/dto"
import Event from "../../../core/internal/event/Event"
import { EventRetryManager } from "../../../core/internal/event/EventRetryManager"
import { EventTransport } from "../../../core/internal/event/EventTransport"

export class BrowserEventDispatcher implements EventDispatcher {
  constructor(
    private readonly transport: EventTransport,
    private readonly shutdownTransport: EventTransport, // maybe beacon
    private readonly retryManager: EventRetryManager
  ) {}

  dispatch(events: Event[], shutdown: boolean): Promise<void> {
    const dto = events.map((it) => it.toDto())
    if (shutdown) {
      return this.shutdownDispatch(dto)
    }
    return this.normalDispatch(dto)
  }

  private async shutdownDispatch(events: EventDto[]): Promise<void> {
    // add to retry manager first in case of fast close
    await this.retryManager.add(events)
    const response = await this.shutdownTransport.send(events)
    if (response.isSuccessful() || response.isClientError()) {
      await this.retryManager.delete(events)
    }
  }

  private async normalDispatch(events: EventDto[]): Promise<void> {
    const response = await this.transport.send(events)
    if (response.isSuccessful() || response.isClientError()) {
      return
    }

    await this.retryManager.add(events)
  }
}
