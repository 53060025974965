import { EventDto } from "../../../core/internal/event/dto"
import { EventRepository } from "../../../core/internal/event/EventRepository"
import { IStorage } from "../../../core/internal/storage/Storage"

export class EventRepositoryImpl implements EventRepository<EventDto> {
  constructor(private storage: IStorage, private storageKey: string, private maxSize: number) {}

  async read(): Promise<EventDto[]> {
    const json = this.storage.getItem(this.storageKey)
    if (json) {
      return JSON.parse(json)
    }
    return []
  }

  async save(items: EventDto[]): Promise<void> {
    if (items.length === 0) {
      return
    }

    const json = JSON.stringify(items.slice(-this.maxSize))
    this.storage.setItem(this.storageKey, json)
  }

  async add(items: EventDto[]): Promise<void> {
    const data = await this.read()
    await this.save(data.concat(items).slice(-this.maxSize))
  }

  async delete(items: EventDto[]): Promise<void> {
    for (let eventDto of await this.read()) {
    }
    const data = await this.read()
    const newData = data.filter((it) => !this.isDeleteTarget(it, items))
    await this.save(newData)
  }

  private isDeleteTarget(item: EventDto, items: EventDto[]): boolean {
    return items.some((it) => it.insertId === item.insertId)
  }

  async clear(): Promise<void> {
    this.storage.removeItem(this.storageKey)
  }
}
