import { Properties, TargetCondition, TargetKey } from "../../model/model"
import ConditionMatcher from "./ConditionMatcher"
import { EvaluatorContext, EvaluatorRequest, EventEvaluatorRequest } from "../evalautor/Evaluator"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import ObjectUtil from "../../util/ObjectUtil"
import Event from "../../event/Event"

export default class EventConditionMatcher implements ConditionMatcher {
  constructor(
    private readonly eventValueResolver: EventValueResolver,
    private readonly valueOperatorMatcher: ValueOperatorMatcher
  ) {}

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    if (!this.isEventEvaluatorRequest(request)) {
      return false
    }
    const eventValue = this.eventValueResolver.resolveOrNull(request.event, condition.key)
    if (ObjectUtil.isNullOrUndefined(eventValue)) {
      return false
    }
    return this.valueOperatorMatcher.matches(eventValue, condition.match)
  }

  private isEventEvaluatorRequest(request: EvaluatorRequest): request is EventEvaluatorRequest {
    return (request as EventEvaluatorRequest).event !== undefined
  }
}

export class EventValueResolver {
  resolveOrNull(event: Event, key: TargetKey): any {
    switch (key.type) {
      case "EVENT_PROPERTY":
        return this.properties(event)?.[key.name]
      case "USER_ID":
      case "USER_PROPERTY":
      case "HACKLE_PROPERTY":
      case "SEGMENT":
      case "AB_TEST":
      case "FEATURE_FLAG":
      case "COHORT":
        throw new Error(`Unsupported TargetKeyType [${key.type}]`)
    }
  }

  private properties(event: Event): Properties | undefined {
    if (Event.isTrack(event)) {
      return event.event.properties
    }
    if (Event.isExposure(event)) {
      return event.properties
    }
    if (Event.isRemoteConfig(event)) {
      return event.properties
    }
    return undefined
  }
}
